import { jarallax, jarallaxElement } from 'jarallax';

// Add Element Extension.
jarallaxElement();

// Add Jarallax on DOM Node.
jarallax( document.querySelectorAll( '[data-jarallax-element]' ) );

jQuery( document ).ready( function ( $ ) {
	$( '#players a' ).on( 'click', function ( e ) {
		e.preventDefault();
		$( this ).find( 'i' ).toggle();

		const audios = $( '#players audio' );
		const audio = $( this ).next( 'audio' )[0];

		audios.each( function ( index, item ) {
			if ( $( item )[0] !== audio ) {
				$( item )[0].pause();
				$( item ).prev( 'a' ).find( '.fa-pause' ).hide();
				$( item ).prev( 'a' ).find( '.fa-play' ).show();
			}
		} );

		if ( audio.paused ) {
			audio.play();
		} else {
			audio.pause();
		}

		const index = $( this ).parents( '.x-box' ).index() + 1;

		$( '#lyrics>div' ).hide();
		$( '#lyrics>div:nth-child(' + index + ')' ).show();

	} );
} );